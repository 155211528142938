<template>
  <div class="col-12"><br>
    <div class="card">
        <div class="card-header">
            <h3 id='ffff'>
                تقرير غياب الطلاب بتاريخ
                <u id="date"></u>
            </h3>
            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="🛠️ خيارات"
            variant="success"
            >
              <b-dropdown-item @click="send()">
                  <i class="fa fa-comment"></i>
                  ارسال رسالة للغائبين
              </b-dropdown-item><hr>
              <b-dropdown-item @click="noorModal()">
                  <i class="fa fa-paper-plane"></i>
                  اضافة الغياب لنور
              </b-dropdown-item><hr>
              <div class="form-group g">
                <span class="g">عرض فصل معين</span>
                <select class="form-control" v-model="selected_classroom" @change="viewClass()">
                  <option value="all">الكل</option>
                  <option v-for="o in classrooms" :key="o" :value="o">{{ o }}</option>
                </select>
              </div><hr>
              <div class="form-group g">
                <span class="g">عرض حالة معينة</span>
                <select class="form-control" v-model="selected_status" @change="viewStatus()">
                  <option value="all">الكل</option>
                  <option value="1">حاضر</option>
                  <option value="2">غائب</option>
                </select>
              </div>
            </b-dropdown>
            <b-dropdown
            id="dropdown-1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="📥 طباعة / تصدير"
            variant="primary"
            >
              <b-dropdown-item @click="printDiv()">
                  <i class="fa fa-print"></i>
                  طباعة التقرير
              </b-dropdown-item><hr>
              <b-dropdown-item @click="exportToExcel()">
                  <i class="fa fa-download"></i>
                  تصدير التقرير اكسل
              </b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="card-body">
            <table id="headerTable" class="table table-stipred table-sm"><div id="date2"></div>
                <thead style="background: #eee">
                    <th>
                      م
                    </th>
                    <th>
                      الطالب
                    </th>
                    <th>
                      الصف
                    </th>
                    <th>
                      الفصل
                    </th>
                    <th>
                      الحالة
                    </th>
                </thead>
                <tbody>
                  <template v-for="(student, index) in students">
                  <tr :key="student.number" :class="student.status == 2 ? 'bg-light-danger' : ''" v-if="student.show">
                    <td>
                      {{ index+1 }}
                    </td>
                    <td>
                      {{ student.name }}
                    </td>
                    <td>
                      {{ student.classname }}
                    </td>
                    <td>
                      {{ student.classroom }}
                    </td>
                    <td>
                      <span @click="student.status = (student.status == 1 ? 2 : 1); refresh()">
                        <button class="btn btn-sm btn-danger" v-if="student.status == 2">
                          <i class="fa fa-times"></i>
                          غائب
                        </button>
                        <button class="btn btn-sm btn-success" v-if="student.status == 1">
                          <i class="fa fa-check"></i>
                          حاضر
                        </button>
                      </span>
                    </td>
                  </tr>
                  </template>
                </tbody>
            </table>
        </div>
    </div>
    <b-modal id="modal-students-ghiab-manual" size="lg" title="ارسال رسائل لاولياء الامور الغائبين" hide-footer>
        <div class="col-12 text-center g">
            <div class="alert alert-success text-center g" v-if="view_archive">
                <img src="/files/done.gif" style="border-radius: 50%">
                <br>
                <br>
                تم استلام الرسائل بنجاح وجاري ارسالها الآن٫<br>
                يمكنك متابعة الارسال <a :href="`/archive?school_id=${school_id}`" target="_blank"><strong><u>من هنا</u></strong></a>
            </div>
            <template v-if="!loading && !view_archive">
                <button class="btn btn-lg btn-primary" v-if="selected == 'sms' || selected == 'whatsapp-api'" @click="sendNow(selected)">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
                <div class="col-12 text-center alert alert-success" v-if="selected == 'whatsapp'" id='remove-me-wa'>
                    <h4>
                        لتتمكن من ارسائل رسائل الواتسآب، يجب عليك تحميل أداة الخدمات الاضافية من سوق كروم.
                        <br><br>
                        <a href="https://bit.ly/3yAC50a" target="_blank" class="btn btn-success">
                            <i class="fa fa-download"></i>
                            إضغط هنا لتثبيت الاداة
                        </a>
                        <br>
                        وبعد تثبيتها قم بإعادة تحديث هذه الصفحة.
                    </h4>
                </div>
                <button class="btn btn-lg btn-primary" v-if="selected == 'whatsapp'" @click="sendNow(selected)" id="brmja-send">
                    <i class="fa fa-check-square"></i>
                    ارسال الآن
                </button>
            </template>
            <template v-if="loading && !view_archive">
                <button class="btn btn-lg btn-primary disabled">
                    جاري الإرسال...
                </button>
            </template>
        </div>
        <div class="form-group">
          <label for="">محتوى الرسالة</label>
          <textarea class="form-control" @keyup="messageChanged()" @change="messageChanged()" v-model="message" rows="5"></textarea>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-sm table-hover" id="brmja-messages-table">
                <thead>
                    <th>
                        الجوال
                    </th>
                    <th>
                        الطالب
                    </th>
                    <th>
                        الرسالة
                    </th>
                </thead>
                <tbody id="students-messages">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show && student.status == 2" :key="c">
                          <td>{{ student.phone }}</td>
                          <td>{{ student.name }}</td>
                          <td><textarea class='form-control msg' readonly v-model="student.message"></textarea></td>
                      </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual"
      @click="sendModal()"
      v-b-modal.modal-students-ghiab-manual
    ></button>

    <b-modal id="modal-students-ghiab-manual2" size="lg" title="اضافة الغياب لنور" hide-footer>
         <div class="col-12 text-center g addGhiabBTN">
              <button class="btn btn-lg btn-success day" @click="addGhiab()" style="display: inline-block;">إضافة الغياب الآن</button>
              <br><br>
              <div class="alert alert-warning g">
                  هذه الخدمة تجريبية، يمكنك تجربتها وإذا واجهتك أي مشكلة قم بالتواصل مع الدعم الفني، وشكراً.
              </div>
              <hr>
          </div>
          <div class="col-12 text-center g">
              <div class="form-group">
                  <label for="">نوع حساب نور (المرحلة)</label>
                  <select class="form-control form-control-lg" style="border: solid 2px red;" v-model="stage">
                      <option value="mt">متوسط</option>
                      <option value="eb">ابتدائي</option>
                      <option value="mas">مسارات</option>
                  </select>
              </div>
              <div class="form-group">
                  <label for="">تغيير الكل إلى:</label>
                  <select class="form-control form-control-lg" style="border: solid 2px red;" @change="changeAllNoorStatuses()" v-model="changeAllNoorStatuses_value">
                      <option value="-99">-- اختر --</option>
                      
						<option value="141,">الغياب بعذر </option>
						<option value="48,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي </option>
						<option value="222,">الغياب بدون عذر مقبول</option>
						<option value="227,">الغياب بعذر</option>
						<option value="142,">الغياب بعذر </option>
						<option value="600627,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي</option>
						<option value="800667,">الغياب بعذر مقبول عبر منصة مدرستي</option>
						<option value="1201153,">الغياب دون عذر مقبول عبر منصة مدرستي</option>
                  </select>
              </div>
          </div>
          <div class="col-12 table-responsive">
              <table class="table table-hover table-striped table-bordered">
                  <thead>
                      <tr style="background: rgb(255, 255, 255);"><th>
                          الطالب
                      </th>
                      <th>
                          نوع المخالفة
                      </th>
                  </tr>
                  </thead>
                  <tbody class="noorStudents">
                    <template v-for="(student, c) in students">
                      <tr v-if="student.show && student.status == 2" :key="c">
                          <td>{{ student.name }}</td>
                          <td>
                            <select style="height: 30px; max-width: 200px;" v-model="student.ghiab">
                              <option value="-99">-- اختر --</option>
                              
						<option value="141,">الغياب بعذر </option>
						<option value="48,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي </option>
						<option value="222,">الغياب بدون عذر مقبول</option>
						<option value="227,">الغياب بعذر</option>
						<option value="142,">الغياب بعذر </option>
						<option value="600627,">الغياب بدون عذر مقبول عن كل يوم دراسي خلال الفصل الدراسي</option>
						<option value="800667,">الغياب بعذر مقبول عبر منصة مدرستي</option>
						<option value="1201153,">الغياب دون عذر مقبول عبر منصة مدرستي</option>
                            </select>
                          </td>
                      </tr>
                    </template>
                  </tbody>
              </table>
          </div>
    </b-modal>
    <button
      class="dnone"
      id="students-ghiab-manual2"
      v-b-modal.modal-students-ghiab-manual2
    ></button>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            changeAllNoorStatuses_value: "-99",
            stage: "mt",
            students: [],
            type: window.location.href.split("type=")[1].split("&")[0],
            school_id: window.location.href.split("school_id=")[1].split("&")[0],
            classrooms: [],
            selected: "",
            selected_classroom: "all",
            selected_status: "all",
            loading: false,
            message: "ابنكم {name} غائب اليوم بتاريخ {date}",
            view_archive: false
        }
    },
    created(){
      var g = this;
      var school_id = g.school_id
      setTimeout(() => {
        $("#date").html(new Date().toISOString().split('T')[0])
        $("body").append(`
          <textarea class='get-local-storage' name='${g.type}_students_${school_id}'></textarea>
        `)
        var i1 = setInterval(() => {
            if($(`.get-local-storage-value[name='${g.type}_students_${school_id}']`).length){
              clearInterval(i1)
              g.students = JSON.parse($(`.get-local-storage-value[name='${g.type}_students_${school_id}']`).val())
              var v = []
              g.students.forEach(element => {
                element.status = 1
                element.ghiab = "-99"
                element.show = true
                if(!g.classrooms.includes(element.classname + ' - ' + element.classroom)){
                  g.classrooms.push(element.classname + ' - ' + element.classroom)
                }
                v.push(element)
              });
              g.students = v
            }
        }, 10);
      }, 500);
    },
    methods: {
        refresh(){
          this.students = JSON.parse(JSON.stringify(this.students))
        },
        printDiv() {
            document.getElementById("date2").innerHTML = document.getElementById("ffff").innerHTML
            var divToPrint=document.getElementById("headerTable");
            var newWin= window.open("");
            newWin.document.write(divToPrint.outerHTML + `<style>
                body{
                    direction: rtl;
                    text-align: right
                }
                thead{
                    background: #eee;
                }
                table, td, th {
                border: 1px solid;
                white-space: nowrap;
                }
                tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                }
                button{
                    background: none;
                    border:none
                }
                </style>`);
            newWin.document.close();
            newWin.print();
            newWin.close();
        },
        exportToExcel() {
            var location = 'data:application/vnd.ms-excel;base64,';
            var excelTemplate = '<html> ' +
                '<head> ' +
                '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/> ' +
                '</head> ' +
                '<body> ' +
                document.getElementById("headerTable").outerHTML +
                '</body> ' +
                '</html>'
            window.location.href = location + btoa(unescape(encodeURIComponent((excelTemplate))));
        },
        viewClass(){
          var g = this;
          var arr = []
          g.students.forEach(function(student){
            student.show = false
            if(g.selected_classroom == "all" || g.selected_classroom == student.classname + ' - ' + student.classroom){
              student.show = true
            }
            arr.push(student)
          })
          g.students = arr
        },
        viewStatus(){
          var g = this;
          var arr = []
          g.students.forEach(function(student){
            student.show = false
            if(g.selected_status == "all" || g.selected_status == student.status){
              student.show = true
            }
            arr.push(student)
          })
          g.students = arr
        },
        send(){
            $("#c-subscribe-ended").click()
            $("#after-send-settings-click").val("#students-ghiab-manual")
        },
        sendModal(){
          var g =this;
          if(localStorage.getItem('send')){
              g.selected = JSON.parse(localStorage.getItem('send')).selected
          }
          g.messageChanged()
        },
        messageChanged(){
          var g = this;
          g.students = g.students.map(function(student){
            student.message = g.message.replace("{name}", student?.name).replace("{date}", new Date().toISOString().split("T")[0])
            return student;
          })
          g.refresh()
        },
        sendNow(method){
            var g = this;
            var c = []
            g.students.forEach(function(s){
              if(s.show && s.status == 2){
                c.push({
                  phone: s.phone,
                  message: s.message
                })
              }
            })
            g.loading = true
            $.post(api + '/api/messages/send', {
                school_id: g.school_id,
                arr: JSON.stringify(c),
                method: method,
                settings: localStorage.getItem('send'),
                type: 'students'
            })
            .then(function(response){
                g.loading = false
                if(response.status == 100){
                    g.view_archive = true
                }else{
                    alert(response.response)
                }
            })
            .fail(function(){
                g.loading = false
                alert('حدث خطأ')
            })
        },
        noorModal(){
          var g = this;
          $("#students-ghiab-manual2").click()
        },
        changeAllNoorStatuses(){
          var g = this;
          g.students = g.students.map(function(a){
            a.ghiab = g.changeAllNoorStatuses_value
            return a;
          })
          g.refresh()
        },
        addGhiab(){
          var g = this;
          $("body").append(`
            <textarea class='save-me-to-local-storage' name='stage'>${g.stage}</textarea>
          `)
          var i1 = setInterval(() => {
            if(!$(".save-me-to-local-storage[name='stage']").length){
              clearInterval(i1)
              $("body").append(`
                <textarea class='save-me-to-local-storage' name='ghiab-type'>day</textarea>
              `)
              var i2 = setInterval(() => {
                if(!$(".save-me-to-local-storage[name='ghiab-type']").length){
                  clearInterval(i2)
                  $("body").append(`
                    <textarea class='save-me-to-local-storage' name='ghiab-students'>${JSON.stringify(g.students.map(function(a){return {name: a.name, status: a.status}}))}</textarea>
                  `)
                  var i3 = setInterval(() => {
                    if(!$(".save-me-to-local-storage[name='ghiab-students']").length){
                      clearInterval(i3)
                      if(confirm('برجاء الذهاب لصفحة "إدخال السلوك والمواظبة" في نور' + '\n هل تريد الذهاب لنور الان؟')){
                        window.open('https://noor.moe.gov.sa/');
                      }
                    }
                  }, 100);
                }
              }, 100);
            }
          }, 100);
        }
    }
}
</script>

<style>
#date2{
    display: none;
}
.b-dropdown.show{
  width: 300px
}
</style>